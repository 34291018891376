import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';

import Loading from './Loading';

import { Route, Link as RouterLink } from 'react-router-dom'

import { default as Routes, getTitle } from './routes';

import { get, setToken } from './util';

const styles = makeStyles(theme => ({
  menu: {
    marginRight: theme.spacing(2),
  },

  bc: {
    padding: theme.spacing(1, 2),
    background: 'rgba(0,0,0,.1)',
    borderRadius: 4,
  },

  title: {
    flex: 1,
  },

  drawer: {
    minWidth: 260,
  },
}));

export default () => {
  const cls = styles();

  const [drawer, setDrawer] = useState(false);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if(window.location.pathname === '/login') {
      setReady(true);
      return;
    }

    get('/admin/ping').then(() => {
      setReady(true);
    }).catch(() => {
      setToken(null);

      // Hard reset
      window.location.pathname = '/login';
    });
  }, []);

  if(!ready) return <Loading />;

  return (
    <div className={cls.root}>
      <Drawer open={drawer} onClose={() => setDrawer(false)}>
        <List className={cls.drawer} onClick={() => setDrawer(false)}>
          <ListItem button component={RouterLink} to="/" className="flat">
            <ListItemIcon><Icon>dashboard</Icon></ListItemIcon>
            <ListItemText primary="总览" />
          </ListItem>
          <Divider />
          <ListItem button component={RouterLink} to="/global" className="flat">
            <ListItemIcon><Icon>build</Icon></ListItemIcon>
            <ListItemText primary="全局设置" />
          </ListItem>
          <ListItem button component={RouterLink} to="/department" className="flat">
            <ListItemIcon><Icon>account_balance</Icon></ListItemIcon>
            <ListItemText primary="院所设置" />
          </ListItem>
          <ListItem button component={RouterLink} to="/users" className="flat">
            <ListItemIcon><Icon>assignment_ind</Icon></ListItemIcon>
            <ListItemText primary="用户管理" />
          </ListItem>
          <Divider />
          <ListItem button className="flat" onClick={() => {
            setToken(null);
            window.location.pathname = '/login';
          }}>
            <ListItemIcon><Icon>exit_to_app</Icon></ListItemIcon>
            <ListItemText primary="登出" />
          </ListItem>
        </List>
      </Drawer>
      <AppBar position="sticky">
        <Toolbar>
          <IconButton edge="start" className={cls.menu} color="inherit" aira-label="Menu" onClick={() => setDrawer(true)}>
            <Icon>menu</Icon>
          </IconButton>
          <Route render={({ location }) => {
            const segs = location.pathname === '/' ? [''] : location.pathname.split('/');
            let acc = '/';
            const accs = [];
            for(const seg of segs) {
              acc += seg;
              accs.push(acc);

              if(acc !== '/') acc += '/';
            }

            const links = accs
              .map(l => [l, getTitle(l)])
              .filter(e => !!e[1])
              .map(([l, t]) => <Link color={l === location.pathname ? 'textPrimary' : 'inherit'} component={RouterLink} to={l} key={l}>{getTitle(l)}</Link>);

            return (
              <Breadcrumbs separator={<Icon fontSize="small">keyboard_arrow_right</Icon>} className={cls.bc}>
                { links }
              </Breadcrumbs>
            );
          }} />
        </Toolbar>
      </AppBar>

      <Routes />
    </div>
  );
}
