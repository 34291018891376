import { default as React, useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/styles';

import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListSubheader from '@material-ui/core/ListSubheader';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';

import { DatePicker, TimePicker } from "@material-ui/pickers";

import Loading from '../Loading';

import { get, post } from '../util';

function formatTime(time) {
  const h = Math.floor(time / 60 / 60);
  const m = Math.floor(time / 60) % 60;

  const hs = h.toString().padStart(2, '0');
  const ms = m.toString().padStart(2, '0');

  return `${hs}:${ms}`;
}

const styles = makeStyles(theme => ({
  div: {
    margin: theme.spacing(2, 0),
  },
  hint: {
    color: 'rgba(0,0,0,.3)',
    marginTop: theme.spacing(2),
  },
  inputRow: {
    display: 'flex',

    '& > *': {
      flex: 1,

      marginRight: theme.spacing(1),

      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  sublist: {
    paddingLeft: 0,
  },
}));

const WEEK_MAP = [
  '星期日',
  '星期一',
  '星期二',
  '星期三',
  '星期四',
  '星期五',
  '星期六',
];

const SEGMENT_MAP = {
  morning: '上午',
  afternoon: '下午',
};

function formatDate(t) {
  const y = String(t.getYear() + 1900).padStart(4, '0');
  const m = String(t.getMonth() + 1).padStart(2, '0');
  const d = String(t.getDate()).padStart(2, '0');

  return `${y}-${m}-${d}`;
}

function printTime(sec) {
  const min = Math.floor(sec / 60);
  const minRes = String(min % 60).padStart(2, '0');
  const hour = String(Math.floor(min / 60)).padStart(2, '0');
  return `${hour}:${minRes}`;
}

function getSpec(detail, noOverride) {
  if(!noOverride)
    for(const override of detail.overrides)
      if(formatDate(new Date(override.date)) === formatDate(new Date()))
        return override;

  const day = new Date().getDay();
  if(day === 6 || day === 7)
    return {
      morning: {
        start: 0,
        end: 0,
      },
      afternoon: {
        start: 0,
        end: 0,
      },
    };

  return {
    morning: detail.morning,
    afternoon: detail.afternoon,
  };
}

function getWorkTime(detail, noOverride = false) {
  const d = getSpec(detail, noOverride);

  if(d.morning.start >= d.morning.end && d.afternoon.start >= d.afternoon.end) return <Typography variant="body1">今日休息</Typography>;

  const rows = [];
  if(d.morning.start < d.morning.end)
    rows.push(<Typography variant="body1" key="morning">上午: { printTime(d.morning.start) } - { printTime(d.morning.end) }</Typography>);

  if(d.afternoon.start < d.afternoon.end)
    rows.push(<Typography variant="body1" key="afternoon">下午: { printTime(d.afternoon.start) } - { printTime(d.afternoon.end) }</Typography>);

  return rows;
}

export default ({ match, history }) => {
  const cls = styles();

  const [detail, setDetail] = useState(null);
  const [tab, setTab] = useState(0);

  const [edit, setEdit] = useState(false);
  const [editDisabled, setEditDisabled] = useState(false);
  const [editSeg, setEditSeg] = useState(0);

  const d = new Date();
  const [editDate, setEditDate] = useState(new Date(d.getYear() + 1900, d.getMonth(), d.getDate()));

  const [editMorningStart, setEditMorningStart] = useState(0);
  const [editAfternoonStart, setEditAfternoonStart] = useState(0);
  const [editMorningEnd, setEditMorningEnd] = useState(0);
  const [editAfternoonEnd, setEditAfternoonEnd] = useState(0);

  const today = new Date();
  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 6);

  async function reload() {
    setDetail(await get(`/admin/channel/${match.params.id}`));
  }

  useEffect(() => {
    reload()
  }, []); // eslint-disable-line

  function editOverride(init, date) {
    if(init) {
      setEditMorningStart(new Date(new Date('2018-01-01T00:00:00+0800').getTime() + init.morning.start * 1000));
      setEditAfternoonStart(new Date(new Date('2018-01-01T00:00:00+0800').getTime() + init.afternoon.start * 1000));
      setEditMorningEnd(new Date(new Date('2018-01-01T00:00:00+0800').getTime() + init.morning.end * 1000));
      setEditAfternoonEnd(new Date(new Date('2018-01-01T00:00:00+0800').getTime() + init.afternoon.end * 1000));

      setEditDate(date);
      setEditDisabled(true);
    } else {
      setEditMorningStart(new Date(new Date('2018-01-01T00:00:00+0800').getTime() + detail.morning.start * 1000));
      setEditAfternoonStart(new Date(new Date('2018-01-01T00:00:00+0800').getTime() + detail.afternoon.start * 1000));
      setEditMorningEnd(new Date(new Date('2018-01-01T00:00:00+0800').getTime() + detail.morning.end * 1000));
      setEditAfternoonEnd(new Date(new Date('2018-01-01T00:00:00+0800').getTime() + detail.afternoon.end * 1000));

      const d = new Date();
      setEditDate(new Date(d.getYear() + 1900, d.getMonth(), d.getDate()));
      setEditDisabled(false);
    }

    setEdit(true);
  }

  async function submitOverride() {
    const payload = {
      morning: {
        start: (editMorningStart - new Date('2018-01-01T00:00:00+0800')) / 1000,
        end: (editMorningEnd - new Date('2018-01-01T00:00:00+0800')) / 1000,
      },
      afternoon: {
        start: (editAfternoonStart - new Date('2018-01-01T00:00:00+0800')) / 1000,
        end: (editAfternoonEnd - new Date('2018-01-01T00:00:00+0800')) / 1000,
      },
    };

    await post(`/admin/channel/${match.params.id}/override/${formatDate(editDate)}`, payload, 'PUT');

    await reload();
    setEdit(false);
  }

  if(!detail)
    return <Loading />;

  return (
    <>
      <Typography variant="body2" className={cls.hint}>窗口名称</Typography>
      <Typography variant="h6" gutterBottom>{ detail.title }</Typography>

      <Tabs value={tab} color="secondary" variant="fullWidth" onChange={(_, t) => setTab(t)}>
        <Tab label="今日摘要" />
        <Tab label="设置" />
      </Tabs>

      { tab === 0 ?
      <>
        <Typography variant="body2" className={cls.hint}>今日工作时段</Typography>
        { getWorkTime(detail) }

        <Typography variant="body2" className={cls.hint}>未来预约</Typography>
        <List subkeader={<li />}>
          { detail.buckets.map(b => <li key={b._id}>
            <ul className={cls.sublist}>
              <ListSubheader>{`${formatDate(new Date(b.date))} ${WEEK_MAP[new Date(b.date).getDay()]} ${SEGMENT_MAP[b.segment]}`}</ListSubheader>
              { b.reservations.map((r, i) => <ListItem key={i}>
                <ListItemIcon><Icon>person</Icon></ListItemIcon>
                <ListItemText primary={<><strong>{ r.user.name }</strong> {r.user.department.name} {r.user.number}</>} secondary={`${formatTime(r.time.start)} - ${formatTime(r.time.end)} ${r.job}`} />
              </ListItem>)}
            </ul>
          </li>)}
          { detail.buckets.length === 0 ? <ListItem>
            <ListItemIcon><Icon>done</Icon></ListItemIcon>
            <ListItemText primary="无预约" />
          </ListItem> : null }
        </List>
      </> : null }

      { tab === 1 ?
      <>
        <Typography variant="body2" className={cls.hint}>默认工作时段</Typography>
        { getWorkTime(detail, true) }

        <Typography variant="body2" className={cls.hint}>未来一周例外工作时段</Typography>
        <List>
          { detail.overrides.map(o =>
            <ListItem button onClick={() => editOverride(o, new Date(o.date))} key={o._id}>
              <ListItemIcon><Icon>access_time</Icon></ListItemIcon>
              <ListItemText primary={formatDate(new Date(o.date))} />
            </ListItem>
          )}
          <ListItem button onClick={() => editOverride()}>
            <ListItemIcon><Icon>add</Icon></ListItemIcon>
            <ListItemText primary="添加" />
          </ListItem>
        </List>

        <Typography variant="body2" className={cls.hint}>工作类别</Typography>
        <List>
          { detail.jobs.map((job, i) => <ListItem key={i}>
            <ListItemIcon><Icon>assignment</Icon></ListItemIcon>
            <ListItemText primary={job.name || (job.time / 60) + " 分钟工作"} secondary={(job.time / 60) + " 分钟"}/>
          </ListItem>) }
        </List>

        <Divider variant="middle" className={cls.div} />

        <Button variant="contained" color="secondary" onClick={() => history.push(`/edit/${match.params.id}`)}>修改</Button>
      </> : null }

      <Dialog open={edit} onClose={() => setEdit(false)}>
        <DialogContent>
          <DatePicker
            label="日期"
            value={editDate}
            color="secondary"
            onChange={e => {
              const year = e.getYear() + 1900;
              const month = e.getMonth();
              const date = e.getDate();

              setEditDate(new Date(year, month, date));
            }}
            format="MM 月 dd 日"
            inputVariant="outlined"
            fullWidth
            margin="normal"
            disabled={editDisabled}
            minDate={today}
            maxDate={maxDate}
          />
          <Tabs
            value={editSeg}
            onChange={(_, v) => setEditSeg(v)}
            textColor="secondary"
            variant="fullWidth"
          >
            <Tab label="上午" />
            <Tab label="下午" />
          </Tabs>

          <div className={cls.inputRow}>
            <TimePicker
              label="工作开始时间"
              value={editSeg === 0 ? editMorningStart : editAfternoonStart}
              onChange={editSeg === 0 ? setEditMorningStart: setEditAfternoonStart}
              margin="normal"
              inputVariant="outlined"
            />

            <TimePicker
              label="工作结束时间"
              value={editSeg === 0 ? editMorningEnd : editAfternoonEnd}
              onChange={editSeg === 0 ? setEditMorningEnd: setEditAfternoonEnd}
              margin="normal"
              inputVariant="outlined"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={submitOverride} color="secondary">确认</Button>
          <Button onClick={() => setEdit(false)}>取消</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
