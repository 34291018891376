import React from 'react';

import { makeStyles } from '@material-ui/styles';

import CircularProgress from '@material-ui/core/CircularProgress';

const styles = makeStyles(theme => ({
  wrapper: {
    margin: theme.spacing(2),
    textAlign: 'center',
  },
}));

export default () => {
  const cls = styles();

  return (
    <div className={cls.wrapper}>
      <CircularProgress color="secondary" />
    </div>
  );
};
