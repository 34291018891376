import React from 'react';

import { makeStyles } from '@material-ui/styles';

import { Route } from 'react-router-dom'

import Dashboard from './Dashboard';
import New from './New';
import Detail from './Detail';
import Department from './Department';
import GlobalConfig from './GlobalConfig';
import Users from './Users';
import Login from './Login';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

export const SPEC = {
  '/': {
    title: '总览',
    comp: Dashboard,
    exact: true,
  },
  '/new': {
    title: '新建窗口',
    comp: New,
    exact: true,
  },
  '/channel/:id': {
    title: '窗口详情',
    comp: Detail,
  },
  '/edit/:id': {
    title: '修改窗口设置',
    comp: New,
  },
  '/department': {
    title: '院所设置',
    comp: Department,
    exact: true,
  },
  '/users': {
    title: '用户管理',
    comp: Users,
    exact: true,
  },
  '/global': {
    title: '全局设置',
    comp: GlobalConfig,
    exact: true,
  },
  '/login': {
    title: '登录',
    comp: Login,
    exact: true,
  },
};

const styles = makeStyles(theme => ({
  wrapper: {
    margin: theme.spacing(4),
  },
  main: {
    padding: theme.spacing(2, 3),
    margin: '0 auto',
    maxWidth: 700,
  },
}));

export default () => {
  const cls = styles();

  return (
    <>
      <div className={cls.wrapper}>
        <Paper elevation={2} className={cls.main}>
          <Route render={(params) => (
            <>
              <Typography gutterBottom variant="h5">{getTitle(params.location.pathname)}</Typography>
              { Object.keys(SPEC).map(l => <Route path={l} key={l} exact={SPEC[l].exact} component={SPEC[l].comp} ${...params} />) }
            </>
          )} />
        </Paper>
      </div>
    </>
  );
}

export function getTitle(l) {
  let t = null;
  for(const k in SPEC) {
    const ksegs = k.split('/');
    const lsegs = l.split('/');
    if(ksegs.length !== lsegs.length) continue;

    if(ksegs.some((kseg, i) => kseg.indexOf(':') !== 0 && kseg !== lsegs[i]))
      continue;

    t = SPEC[k].title;
    break;
  }

  if(!t) return null;

  if(typeof t === 'function')
    return <Route render={params => t(params)} />;
  else return t;
}
