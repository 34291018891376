import { default as React, useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/styles';

import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import Loading from '../Loading';

import { get, post } from '../util';

const styles = makeStyles(theme => ({
  div: {
    margin: theme.spacing(2, 0),
  },

  inputRow: {
    display: 'flex',

    '& > *': {
      flex: 1,

      marginRight: theme.spacing(1),

      '&:last-child': {
        marginRight: 0,
      },
    },
  },

  hint: {
    padding: '40px 0',
    textAlign: 'center',
    fontSize: 16,
    color: 'rgba(0,0,0,.54)',
  },
}));

export default ({ history, match }) => {
  const cls = styles();

  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [result, setResult] = useState(null);

  const [edit, setEdit] = useState(false);
  const [editTarget, setEditTarget] = useState({});

  useEffect(() => {
    get('/admin/department').then(setDepartments);
  }, []);

  async function update() {
    setLoading(true);
    const segs = query.split(' ').filter(e => !!e);
    if(segs.length === 0) {
      setResult(null);
      return;
    }

    const tokens = segs.join('+');
    const resp = await get(`/admin/user/query/${tokens}`);
    setResult(resp);
    setLoading(false);
  }

  async function commit() {
    await post(`/admin/user/${editTarget._id}`, editTarget, 'PUT');
    await update();
    setEdit(false);
  }

  return (
    <>
      <TextField
        label="搜索"
        value={query}
        onChange={e => setQuery(e.target.value)}
        fullWidth
        margin="normal"
        variant="filled"
        disabled={loading}
        onKeyDown={e => {
          if(e.key === 'Enter')
            update();
        }}
      />

      { loading ? <Loading /> : null }
      { !loading && result === null ? <div className={cls.hint}>请在上方输入后回车进行搜索</div> : null }
      { !loading && result !== null && result.length === 0 ? <div className={cls.hint}>无结果</div> : null }
      { !loading && result !== null && result.length > 0 ? <List>
        { result.map(p => <ListItem button key={p._id} onClick={() => {
          setEdit(true);
          setEditTarget({
            _id: p._id,
            name: p.name,
            number: p.number,
            department: p.department._id,
          });
        }}>
          <ListItemIcon><Icon>person</Icon></ListItemIcon>
          <ListItemText primary={p.name} secondary={p.department.name} />
        </ListItem>) }
      </List> : null }

      <Dialog open={edit} onClose={() => setEdit(false)}>
        <DialogContent>
          <TextField
            label="姓名"
            value={editTarget.name || ""}
            onChange={e => {
              setEditTarget({ ...editTarget, name: e.target.value });
            }}
            fullWidth
            margin="normal"
            variant="outlined"
          />

          <TextField
            label="院系"
            value={editTarget.department || ""}
            select
            onChange={e => {
              setEditTarget({ ...editTarget, department: e.target.value });
            }}
            fullWidth
            margin="normal"
            variant="outlined"
          >
            { departments.map(e => <MenuItem key={e._id} value={e._id} className="flat">
              { e.name }
            </MenuItem>) }
          </TextField>

          <TextField
            label="手机"
            value={editTarget.number || ""}
            onChange={e => {
              setEditTarget({ ...editTarget, number: e.target.value });
            }}
            fullWidth
            margin="normal"
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => commit()}>确定</Button>
          <Button onClick={() => setEdit(false)}>取消</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
