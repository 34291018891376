import React from 'react';
import './App.css';

import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';

import { blueGrey, blue } from '@material-ui/core/colors';

import { BrowserRouter } from 'react-router-dom';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import Frame from './Frame';

const theme = createMuiTheme({
  palette: {
    primary: { main: blueGrey[200] },
    secondary: blue,
  },
  overrides: {
    MuiListItem: {
      button: {
        '&:not(.flat)': {
          position: 'relative',

          boxShadow: 'rgba(0,0,0,.3) 0 1px 3px',
          borderLeft: `${blue[100]} 5px solid`,
          borderRadius: 2,

          marginBottom: 5,

          '& + hr': {
            height: 3,
          },

          'hr + &': {
            marginTop: 5,
          }
        },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Frame />
        </MuiPickersUtilsProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
