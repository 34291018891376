import { default as React, useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/styles';

import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import { Link as RouterLink } from 'react-router-dom'

import Loading from '../Loading';

import { get } from '../util';

const styles = makeStyles(theme => ({
}));

export default () => {
  const cls = styles(); // eslint-disable-line no-unused-vars

  const [stat, setStat] = useState(null);

  async function reload() {
    setStat(await get('/admin/channel'));
  }

  useEffect(() => {
    reload()
  }, []);

  if(!stat)
    return <Loading />;

  return (
    <>
      <List>
        { stat.map(s =>
          <ListItem button component={RouterLink} to={`/channel/${s._id}`} key={s._id}>
            <ListItemIcon><Icon>list_alt</Icon></ListItemIcon>
            <ListItemText primary={s.title} secondary={`目前预约: ${s.count} 人`} />
          </ListItem>
        )}
        <ListItem button component={RouterLink} to="/new">
          <ListItemIcon><Icon>add</Icon></ListItemIcon>
          <ListItemText primary="添加窗口" />
        </ListItem>
      </List>
    </>
  );
};
