import { default as React, useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/styles';

import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Divider from '@material-ui/core/Divider';
import Snackbar from '@material-ui/core/Snackbar';

import Loading from '../Loading';

import { get, post } from '../util';

import { DatePicker, TimePicker } from "@material-ui/pickers";

function formatDate(d) {
  return `${d.getYear() + 1900}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}`
}

const styles = makeStyles(theme => ({
  div: {
    margin: theme.spacing(2, 0),
  },

  inputRow: {
    display: 'flex',

    '& > *': {
      flex: 1,

      marginRight: theme.spacing(1),

      '&:last-child': {
        marginRight: 0,
      },
    },
  },

  alterClosedBtn: {
    marginTop: theme.spacing(2),
  },

  closedTime: {
    padding: '40px 20px',
    textAlign: 'center',
    marginTop: theme.spacing(1),
    background: '#EEE',
  }
}));

export default () => {
  const cls = styles();

  const [editing, setEditing] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [snack, setSnack] = useState(false);

  const [morningStart, setMorningStart] = useState(new Date('2018-01-01T09:00:00+0800'));
  const [afternoonStart, setAfternoonStart] = useState(new Date('2018-01-01T14:00:00+0800'));
  const [morningEnd, setMorningEnd] = useState(new Date('2018-01-01T11:00:00+0800'));
  const [afternoonEnd, setAfternoonEnd] = useState(new Date('2018-01-01T17:00:00+0800'));

  const [jobs, setJobs] = useState([]);

  const [editJob, setEditJob] = useState(false);
  const [editJobTarget, setEditJobTarget] = useState({});

  const [contact, setContact] = useState('');
  const [success, setSuccess] = useState('');

  const [closed, setClosed] = useState(null);
  const [addClosed, setAddClosed] = useState(false);

  async function reload() {
    const payload = await get('/admin/global');

    setMorningStart(new Date(new Date('2018-01-01T00:00:00+0800').getTime() + payload.morning.start * 1000));
    setAfternoonStart(new Date(new Date('2018-01-01T00:00:00+0800').getTime() + payload.afternoon.start * 1000));
    setMorningEnd(new Date(new Date('2018-01-01T00:00:00+0800').getTime() + payload.morning.end * 1000));
    setAfternoonEnd(new Date(new Date('2018-01-01T00:00:00+0800').getTime() + payload.afternoon.end * 1000));

    setJobs((payload.jobs || []).map(({ name, time }) => ({ name, time: time / 60 })));

    setContact(payload.contact || '');
    setSuccess(payload.success || '');

    setClosed(payload.closed ? {
      from: new Date(payload.closed.from),
      to: new Date(payload.closed.to),
    } : null);

    setLoaded(true);
  }

  useEffect(() => {
    reload();
  }, []);

  async function update() {
    const payload = {
      morning: {
        start: (new Date(morningStart) - new Date('2018-01-01T00:00:00+0800')) / 1000,
        end: (new Date(morningEnd) - new Date('2018-01-01T00:00:00+0800')) / 1000,
      },

      afternoon: {
        start: (new Date(afternoonStart) - new Date('2018-01-01T00:00:00+0800')) / 1000,
        end: (new Date(afternoonEnd) - new Date('2018-01-01T00:00:00+0800')) / 1000,
      },

      jobs: jobs.map(({ name, time }) => ({ name, time: time * 60 })),

      contact,
      success,

      closed,
    };
    await post(`/admin/global`, payload, 'PUT');
    await reload();
    setSnack(true);
  }

  if(!loaded)
    return <Loading />;

  const today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);

  return (
    <>
      <Typography variant="h6">默认工作时段</Typography>
      <Tabs
        value={editing}
        onChange={(_, v) => setEditing(v)}
        textColor="secondary"
        variant="fullWidth"
      >
        <Tab label="上午" />
        <Tab label="下午" />
      </Tabs>

      <div className={cls.inputRow}>
        <TimePicker
          label="工作开始时间"
          helperText="可以在创建后对于特定日期单独修改"
          value={editing === 0 ? morningStart : afternoonStart}
          onChange={editing === 0 ? setMorningStart: setAfternoonStart}
          margin="normal"
          inputVariant="outlined"
        />

        <TimePicker
          label="工作结束时间"
          helperText="可以在创建后对于特定日期单独修改"
          value={editing === 0 ? morningEnd : afternoonEnd}
          onChange={editing === 0 ? setMorningEnd: setAfternoonEnd}
          margin="normal"
          inputVariant="outlined"
        />
      </div>

      <Divider variant="middle" className={cls.div} />

      <Typography variant="h6">工作列表</Typography>

      <List>
        { jobs.map((job, i) => <ListItem key={i} button onClick={() => {
          setEditJobTarget(job);
          setEditJob(true);
        }}>
          <ListItemIcon><Icon>assignment</Icon></ListItemIcon>
          <ListItemText primary={job.name || job.time + " 分钟工作"} secondary={job.time + " 分钟"}/>
        </ListItem>) }
        <ListItem button onClick={() => {
          const appending = { name: '工作', time: 10 };
          const newJobs = [...jobs, appending];
          setEditJob(true);
          setEditJobTarget(appending)
          setJobs(newJobs);
        }}>
          <ListItemIcon><Icon>add</Icon></ListItemIcon>
          <ListItemText primary="添加" />
        </ListItem>
      </List>

      <Divider variant="middle" className={cls.div} />

      <Typography variant="h6">提示信息</Typography>

      <TextField
        label="联系方式"
        value={contact}
        onChange={ev => setContact(ev.target.value)}
        variant="outlined"
        fullWidth
        margin="normal"
      />

      <TextField
        label="预约成功提示"
        value={success}
        onChange={ev => setSuccess(ev.target.value)}
        variant="outlined"
        fullWidth
        multiline
        margin="normal"
      />

      <Divider variant="middle" className={cls.div} />

      <Typography variant="h6">关闭时段</Typography>
      <div className={cls.closedTime}>
        { !closed ?
            <Typography variant="body1">不设置关闭时段</Typography>
            :
            <Typography variant="body1"><strong>{formatDate(closed.from)}</strong> ~ <strong>{formatDate(closed.to)}</strong></Typography>
        }
      </div>

      { !closed ?
          <Button variant="contained" className={cls.alterClosedBtn} onClick={() => {
            setClosed({
              from: today,
              to: today,
            });
            setAddClosed(true);
          }}>添加</Button>
          :
          <Button variant="contained" className={cls.alterClosedBtn} onClick={() => setClosed(null)}>删除</Button>
      }

      <Divider variant="middle" className={cls.div} />

      <Button variant="contained" size="large" color="secondary" disabled={jobs.length === 0} onClick={update}>提交</Button>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snack}
        autoHideDuration={2000}
        onClose={() => setSnack(false)}
        message="更新成功"
      />

      <Dialog open={editJob} onClose={() => setEditJob(false)}>
        <DialogContent>
          <TextField
            label="名称"
            value={editJobTarget.name || ""}
            onChange={e => {
              const newJobs = [...jobs];
              editJobTarget.name = e.target.value;
              setJobs(newJobs);
            }}
            fullWidth
            margin="normal"
            variant="outlined"
          />
          <TextField
            label="时长 (分钟)"
            type="number"
            value={editJobTarget.time || 0}
            onChange={e => {
              const newJobs = [...jobs];
              editJobTarget.time = e.target.value;
              setJobs(newJobs);
            }}
            fullWidth
            margin="normal"
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => setEditJob(false)}>确定</Button>
          <Button onClick={() => {
            const idx = jobs.findIndex(e => e === editJobTarget);
            if(idx < 0) return;

            const newJobs = [...jobs];
            newJobs.splice(idx, 1);
            setJobs(newJobs);
            setEditJob(false);
          }}>删除</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={addClosed} onClose={() => setAddClosed(false)}>
        <DialogContent>
          <DatePicker
            label="自"
            value={(closed || {}).from}
            color="secondary"
            onChange={e => {
              const year = e.getYear() + 1900;
              const month = e.getMonth();
              const date = e.getDate();

              const d = new Date(year, month, date);

              setClosed({
                to: closed.to >= d ? closed.to : d,
                from: d,
              });
            }}
            format="yyyy 年 MM 月 dd 日"
            inputVariant="outlined"
            fullWidth
            margin="normal"
            minDate={today}
          />
          <DatePicker
            label="至"
            value={(closed || {}).to}
            color="secondary"
            onChange={e => {
              const year = e.getYear() + 1900;
              const month = e.getMonth();
              const date = e.getDate();

              setClosed({
                from: closed.from,
                to: new Date(year, month, date),
              });
            }}
            format="yyyy 年 MM 月 dd 日"
            inputVariant="outlined"
            fullWidth
            margin="normal"
            minDate={closed ? closed.from : today}
          />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => setAddClosed(false)}>确定</Button>
          <Button onClick={() => {
            setClosed(null);
            setAddClosed(false);
          }}>取消</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
