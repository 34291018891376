import { default as React, useState, useCallback } from 'react';

import { makeStyles } from '@material-ui/styles';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Snackbar from '@material-ui/core/Snackbar';

import { post, setToken } from '../util';

const styles = makeStyles(theme => ({
  div: {
    margin: theme.spacing(2, 0),
  },
}));

export default ({ history }) => {
  const cls = styles();

  const [user, setUser] = useState('');
  const [pass, setPass] = useState('');
  const [failed, setFailed] = useState(false);

  const login = useCallback(async () => {
    try {
      const { token } = await post('/admin/login', { user, pass });
      setToken(token);
      history.push('/');
    } catch(e) {
      if(e.code === 403)
        setFailed(true);
      else throw e;
    }
  }, [user, pass, history]);

  return (
    <>
      <TextField
        label="用户名"
        value={user}
        onChange={e => setUser(e.target.value)}
        fullWidth
        margin="normal"
        variant="outlined"
      />

      <TextField
        label="密码"
        value={pass}
        onChange={e => setPass(e.target.value)}
        type="password"
        fullWidth
        margin="normal"
        variant="outlined"
      />

      <Divider variant="middle" className={cls.div} />

      <Button disabled={!user || !pass} variant="contained" color="secondary" size="large" onClick={login}>登录</Button>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={failed}
        autoHideDuration={2000}
        onClose={() => setFailed(false)}
        message="用户名或密码错误！"
      />
    </>
  );
};
