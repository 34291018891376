import { default as React, useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/styles';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import Loading from '../Loading';

import { get, post } from '../util';

const styles = makeStyles(theme => ({
  div: {
    margin: theme.spacing(2, 0),
  },

  inputRow: {
    display: 'flex',

    '& > *': {
      flex: 1,

      marginRight: theme.spacing(1),

      '&:last-child': {
        marginRight: 0,
      },
    },
  },
}));

export default ({ history, match }) => {
  const cls = styles();

  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');

  useEffect(() => {
    if(match.params.id) {
      setLoading(true);
      get(`/admin/channel/${match.params.id}`).then(payload => {
        setTitle(payload.title);
        setLoading(false);
      });
    }
  }, [match.params.id])

  async function submit() {
    const payload = {
      title,
    };

    let _id = match.params.id;
    if(!_id) {
      _id = (await post('/admin/channel', payload))._id;
    } else {
      await post(`/admin/channel/${match.params.id}`, payload, 'PUT');
    }

    history.push(`/channel/${_id}`);
  }

  if(loading)
    return <Loading />;

  return (
    <>
      <TextField
        label="标题"
        value={title}
        onChange={e => setTitle(e.target.value)}
        fullWidth
        margin="normal"
        variant="outlined"
      />

      <Divider variant="middle" className={cls.div} />

      <Button disabled={!title} variant="contained" color="secondary" size="large" onClick={submit}>提交</Button>
    </>
  );
};
