import Config from './config';

let token = window.localStorage.getItem('token') || null;

async function parseResp(resp) {
  if(resp.status >= 400) throw { error: resp.statusText, code: resp.status }; // eslint-disable-line no-throw-literal
  else if(resp.status === 204) return null;
  else if(resp.headers.get('Content-Type').indexOf('application/json') === 0)
    return await resp.json();
  return await resp.text();
}

export async function get(endpoint, method='GET') {
  const headers = {};

  if(token)
    headers.Authorization = `Bearer ${token}`;

  const resp = await fetch(`${Config.BACKEND}${endpoint}`, {
    method,
    headers,
  });

  return await parseResp(resp);
}

export async function post(endpoint, body, method='POST') {
  const headers = {
    'Content-Type': 'application/json',
  };

  if(token)
    headers.Authorization = `Bearer ${token}`;

  const resp = await fetch(`${Config.BACKEND}${endpoint}`, {
    method,
    headers,

    body: JSON.stringify(body),
  });

  return await parseResp(resp);
}

export function setToken(_token) {
  token = _token;

  window.localStorage.setItem('token', token);
}
