import { default as React, useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/styles';

import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';

import Loading from '../Loading';

import { get, post } from '../util';

const styles = makeStyles(theme => ({
  dialogContent: {
    width: 300,
  },
}));

export default () => {
  const cls = styles();

  const [dept, setDept] = useState(null);
  const [channel, setChannel] = useState(null);
  const [channelMap, setChannelMap] = useState(null);
  const [creating, setCreating] = useState(false);
  const [editing, setEditing] = useState(false);
  const [editTarget, setEditTarget] = useState({});

  async function reload() {
    const [d, c] = await Promise.all([
      get('/admin/department'),
      get('/admin/channel'),
    ]);

    d.sort((a, b) => a.name.localeCompare(b.name, 'zh-CN-u-co-pinyin'));

    const m = new Map();
    for(const row of c)
      m.set(row._id, row);

    setDept(d);
    setChannel(c)
    setChannelMap(m);
  }

  useEffect(() => {
    reload()
  }, []);

  async function del(name) {
    await get(`/admin/department/${name}`, 'DELETE');
    await reload();
    setEditing(false);
  }

  async function submit(id, channel, secondaryChannel, name) {
    const payload = {
      channel,
      secondaryChannel,
      name,
    };
    console.log(payload);

    if(id)
      await post(`/admin/department/${id}`, payload, 'PUT');
    else
      await post(`/admin/department`, payload);
    await reload();
    setEditing(false);
  }

  if(!dept || !channel || !channelMap)
    return <Loading />;

  return (
    <>
      <List>
        <ListItem button onClick={() => {
          setCreating(true);
          setEditing(true);
          setEditTarget({ name: '新院所' });
        }}>
          <ListItemIcon><Icon>add</Icon></ListItemIcon>
          <ListItemText primary="添加院所" />
        </ListItem>
        { dept.map((d, i) => <ListItem button key={d._id} onClick={() => {
          setCreating(false);
          setEditing(true);
          setEditTarget(d);
        }}>
          <ListItemIcon><Icon>account_balance</Icon></ListItemIcon>
          <ListItemText primary={d.name} secondary={'窗口: ' + channelMap.get(d.channel).title + (!d.secondaryChannel ? '' : ' / ' + channelMap.get(d.secondaryChannel).title) }/>
        </ListItem>) }
      </List>

      <Dialog open={editing} onClose={() => setEditing(false)}>
        <DialogContent className={cls.dialogContent}>
          <TextField
            label="名称"
            value={editTarget.name || ''}
            onChange={e => {
              setEditTarget({ ...editTarget, name: e.target.value });
            }}
            fullWidth
            margin="normal"
            variant="outlined"
          />

          <List>
            { creating ? null : <ListItem button disabled={!editTarget.name} onClick={() => submit(editTarget._id, editTarget.channel, editTarget.secondaryChannel || null, editTarget.name)}>
              <ListItemIcon><Icon>edit</Icon></ListItemIcon>
              <ListItemText
                primary="仅保存名称"
                secondary={'当前主要窗口: '
                    + ((channelMap.get(editTarget.channel) ? channelMap.get(editTarget.channel).title : ''))
                }
              />
            </ListItem>}
            { creating || !editTarget.secondaryChannel ? null : <ListItem button onClick={() => submit(editTarget._id, editTarget.channel, null, editTarget.name)}>
              <ListItemIcon><Icon>delete</Icon></ListItemIcon>
              <ListItemText primary="撤销备用窗口" secondary={(channelMap.get(editTarget.secondaryChannel) ? channelMap.get(editTarget.secondaryChannel).title : '')} />
            </ListItem>}
            { creating ? null : <ListItem button onClick={() => del(editTarget._id)}>
              <ListItemIcon><Icon>delete</Icon></ListItemIcon>
              <ListItemText primary="删除" />
            </ListItem>}
            { creating ? null : <Divider /> }
            { channel.filter(c => c._id !== editTarget.channel).map(c => <ListItem
              key={c._id}
              button
              disabled={!editTarget.name}
              onClick={() => submit(editTarget._id, c._id, c._id === editTarget.secondaryChannel ? null : (editTarget.secondaryChannel || null), editTarget.name)}
            >
              <ListItemIcon><Icon>list_alt</Icon></ListItemIcon>
              <ListItemText primary={c.title} secondary="选为主要窗口" />
            </ListItem>) }
            { creating || channel.filter(c => c._id !== editTarget.channel && c._id !== editTarget.secondaryChannel).length === 0 ? null : <Divider /> }
            { creating ? null : channel.filter(c => c._id !== editTarget.channel && c._id !== editTarget.secondaryChannel).map(c => <ListItem
              key={c._id}
              button
              disabled={!editTarget.name}
              onClick={() => submit(editTarget._id, editTarget.channel, c._id, editTarget.name)}
            >
              <ListItemIcon><Icon>settings_backup_restore</Icon></ListItemIcon>
              <ListItemText primary={c.title} secondary="选为备用窗口" />
            </ListItem>) }
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
};
